import React from "react";
import {Report} from "../../../../api/im";
import FormHeader from "../../../Form/FormHeader";
import FormActionContainer from "../../../Form/FormActionContainer";
import {IncidentType} from "../../../../api/reports/api";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {useDispatch} from "react-redux";
import {setReportFromType} from "../../../../store/reports/actions/ReportActions";
import {useUserData} from "../../../Hooks/useUserData";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";

const ReportTypeButtons = (props: Report) => {
    const dispatch = useDispatch();
    const user = useUserData();

    const renderOptionButtons = (key: string, report: Report) => {
        switch (key) {
            case IncidentType.Complaint:
                return (
                    <AuthAmI
                        accessLevels={[
                            StaffAccessLevel.DutyManager,
                            StaffAccessLevel.SystemAdministrator
                        ]}
                    >
                        <div className="report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={getUiFriendlyText(key)}
                                className="w-100"
                                onClick={() => {
                                    // Don't want to reset the form if we reselect
                                    if (key === report.type) return;
                                    dispatch(
                                        setReportFromType(
                                            getIncidentTypesFromString(key),
                                            props,
                                            user
                                        )
                                    );
                                }}
                                colour={
                                    key === report.type
                                        ? ButtonColourOptions.Yellow
                                        : ButtonColourOptions.DarkBlue
                                }
                                disabled={props.id > 0}
                            />
                        </div>
                    </AuthAmI>
                );
            case IncidentType.Incident:
                return (
                    <div className="report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={getUiFriendlyText(key)}
                            className="w-100"
                            onClick={() => {
                                // Don't want to reset the form if we reselect
                                if (key === report.type) return;
                                dispatch(
                                    setReportFromType(getIncidentTypesFromString(key), props, user)
                                );
                            }}
                            colour={
                                key === report.type
                                    ? ButtonColourOptions.Yellow
                                    : ButtonColourOptions.DarkBlue
                            }
                            disabled={props.id > 0}
                        />
                    </div>
                );
            case IncidentType.VehicleDefect:
            case IncidentType.EquipmentDefect:
                return (
                    <div className="report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            colour={ButtonColourOptions.DarkBlue}
                            innerValue={getUiFriendlyText(key)}
                            className="w-100"
                            disabled={props.id > 0}
                            onClick={() => {
                                switch (key) {
                                    case IncidentType.EquipmentDefect:
                                        window
                                            .open(
                                                "https://fms.medicare-ems.co.uk/public/fms/forms/equipment_defect",
                                                "_blank"
                                            )
                                            ?.focus();
                                        break;

                                    case IncidentType.VehicleDefect:
                                        window
                                            .open(
                                                "https://fms.medicare-ems.co.uk/public/fms/forms/vehicle_defect",
                                                "_blank"
                                            )
                                            ?.focus();
                                        break;
                                }
                            }}
                        />
                    </div>
                );
            case IncidentType.None:
                return undefined;
        }
    };

    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={"Report Type"} />
                <FormActionContainer>
                    <React.Fragment>
                        <div className="report-buttons">
                            {Object.keys(IncidentType).map((item: string) => {
                                return renderOptionButtons(item, props);
                            })}
                        </div>
                    </React.Fragment>
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default ReportTypeButtons;

function getIncidentTypesFromString(value: string): IncidentType {
    return IncidentType[value as keyof typeof IncidentType];
}
