import React from "react";
import {ReportListTableRow} from "./ReportListDesktopView";
import AuthAmI from "../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../api/staff";
import IconWithModal from "../../../../Icon/IconWithModal";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {IconType} from "../../../../Icon/Icon";
import {deleteReportFromService} from "../../../../../store/reports/actions/ReportActions";
import {useHistory} from "react-router-dom";
import ReportStateAmI from "../Shared/ReportStateAmI";
import {ReportState} from "../../../../../api/im";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import {routeNames} from "../../../../Navigation/routeNames";
import {showSuccessToast} from "../../../../../utils/toastUtils";
import * as H from "history";

function reviewReport(history: H.History, searchQueries: string, item: ReportListTableRow) {
    history.push({
        pathname: `${routeNames.reportReview.path}/${item.actions}`,
        search: searchQueries
    });
}

function viewReadonlyReport(history: H.History, searchQueries: string, item: ReportListTableRow) {
    history.push({
        pathname: `${routeNames.reportView.path}/${item.actions}`,
        search: searchQueries
    });
}

function editReport(history: H.History, searchQueries: string, item: ReportListTableRow) {
    history.push({
        pathname: `${routeNames.reportEdit.path}/${item.actions}`,
        search: searchQueries
    });
}

export function ReportListDirectClick({item, label}: {item: ReportListTableRow; label: string}) {
    const {searchQueries} = usePageUrl();
    const history = useHistory();

    return (
        <>
            <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                <ReportStateAmI
                    reportStates={[ReportState.PendingReview]}
                    currentReportState={item.reportState}
                >
                    <div
                        className="clickable"
                        onClick={() => editReport(history, searchQueries, item)}
                    >
                        {label}
                    </div>
                </ReportStateAmI>
                <ReportStateAmI
                    reportStates={[ReportState.UnderReview, ReportState.CompletedReview]}
                    currentReportState={item.reportState}
                >
                    <div
                        className="clickable"
                        onClick={() => viewReadonlyReport(history, searchQueries, item)}
                    >
                        {label}
                    </div>
                </ReportStateAmI>
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                <div
                    className="clickable"
                    onClick={() => reviewReport(history, searchQueries, item)}
                >
                    {label}
                </div>
            </AuthAmI>
        </>
    );
}

const ReportListActionItem = ({item, onActionConfirmed}: ReportListActionItemProps) => {
    const dispatch = useDispatch();
    const {loading} = useSelector((state: RootStore) => state.report);

    const onDeleteReport = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteReportFromService(item.actions));
        if (!success) {
            return false;
        }
        showSuccessToast("Deleted report");

        await onActionConfirmed();
        return success;
    };

    return (
        <React.Fragment>
            <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                <ReportStateAmI
                    reportStates={[ReportState.PendingReview]}
                    currentReportState={item.reportState}
                >
                    <div className="row ml-0 mr-0">
                        <IconWithModal
                            tooltipText={`Delete report?`}
                            iconType={IconType.Bin}
                            modalTitle={"Delete Report"}
                            modalBody={
                                "Are you sure you want to delete this report?. This operation cannot be undone!"
                            }
                            onConfirm={onDeleteReport}
                            loading={loading}
                        />
                    </div>
                </ReportStateAmI>
                <ReportStateAmI
                    reportStates={[ReportState.UnderReview, ReportState.CompletedReview]}
                    currentReportState={item.reportState}
                >
                    <div className="row ml-0 mr-0"></div>
                </ReportStateAmI>
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                <div className="row ml-0 mr-0">
                    <IconWithModal
                        tooltipText={`Delete report?`}
                        iconType={IconType.Bin}
                        modalTitle={"Delete Report"}
                        modalBody={
                            "Are you sure you want to delete this report?. This operation cannot be undone!"
                        }
                        onConfirm={onDeleteReport}
                        loading={loading}
                    />
                </div>
            </AuthAmI>
        </React.Fragment>
    );
};

export default ReportListActionItem;

interface ReportListActionItemProps {
    item: ReportListTableRow;
    onActionConfirmed: () => Promise<void>;
}
